<template>
  <div class="req-temp-container">
    <vs-row class="items-start">
      <vs-col vs-type="flex" vs-w="12">
        <vs-card class="custom-vx-card">
          <vs-table class="webhook-table" :data="exportList">
            <template class="tableHeader" slot="thead">
              <vs-th> Date created </vs-th>
              <vs-th> Filename </vs-th>
              <vs-th> Type </vs-th>
              <vs-th> Action </vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.createdAt">{{ formatDateOrder(tr.createdAt) }}</vs-td>
                <vs-td :data="tr.fileName">{{ tr.fileName }}</vs-td>
                <vs-td :data="tr.type">{{tr.type}}</vs-td>
                <vs-td :data="tr"><s3FileGenerator :document="tr.file" :isText="true"/></vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import s3FileGenerator from "../../../components/S3FileGenerator.vue"
import { mapActions } from "vuex";

export default {
  components: { s3FileGenerator },
  name: "index",
  data() {
    return {
      exportList: [],
    };
  },

  mounted() {
    this.checkAdminLogin();
    this.fetch();
  },

  methods: {
    ...mapActions("dataExport", ["fetchData"]),
    ...mapActions("admin", ["checkLogin"]),

    async checkAdminLogin() {
      await this.checkLogin();
    },

    async fetch() {
      await this.fetchData().then((response) => {
        this.exportList = response.data.data;
      });
    },
  },
};
</script>
